import {
  Button,
  ButtonDropdown,
} from '@cloudscape-design/components';
import { InlineNotification, Modal } from 'carbon-components-react';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { useUser } from 'reactfire';

import { usePartAndQuote } from '../../features/firebase';

function QuoteStatus({ partId }) {
  const { part, quote } = usePartAndQuote(partId);
  const { email, uid } = part;
  const {
    status, hasPendingWrites, id: quoteID, feedbackChecklistReviewed,
  } = quote;
  const { data: user } = useUser();
  let allowLiveChanges = false;
  if ([
    'andy@parallelfluidics.com',
    'josh@parallelfluidics.com',
    'jonathan@parallelfluidics.com',
  ].includes(user.email)) {
    allowLiveChanges = true;
  }

  const [isSetQuotedOpen, setIsSetQuotedOpen] = useState(false);
  const [isSetNoQuoteOpen, setIsSetNoQuoteOpen] = useState(false);
  const [isRevokeQuoteOpen, setIsRevokeQuoteOpen] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = async (state) => {
    const functions = getFunctions();
    const sendQuoteEmail = httpsCallable(functions, 'sendQuoteEmail');
    await sendQuoteEmail({ userID: uid, type: state, newUser: !!email });
  };

  const handleChangeState = async (state) => {
    await updateDoc(doc(getFirestore(), 'quotes', quoteID), { status: state });
  };

  if (hasPendingWrites || !status) {
    return <Button disabled>Loading...</Button>;
  }

  if (status === 'pending') {
    return (
      <>
        {typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <Modal
              size="md"
              open={isSetQuotedOpen}
              onRequestClose={() => { setIsSetQuotedOpen(false); }}
              modalHeading="Mark quoted"
              primaryButtonText="Confirm and send email"
              secondaryButtons={allowLiveChanges ? [
                { buttonText: 'Cancel', onClick: () => { setIsSetQuotedOpen(false); } },
                {
                  buttonText: 'Confirm without email',
                  onClick: async () => {
                    await handleChangeState('quoted');
                    setIsSetQuotedOpen(false);
                  },
                },
              ] : [
                { buttonText: 'Cancel', onClick: () => { setIsSetQuotedOpen(false); } },
              ]}
              onRequestSubmit={async () => {
                setIsSubmitting(true);
                try {
                  setEmailError(null);
                  await sendEmail('quoted');
                  await handleChangeState('quoted');
                  setIsSetQuotedOpen(false);
                } catch (err) {
                  setEmailError(err.message);
                }
                setIsSubmitting(false);
              }}
              primaryButtonDisabled={!allowLiveChanges || isSubmitting}
            >
              { emailError ? (
                <InlineNotification
                  kind="error"
                  title="Error sending email."
                  subtitle={emailError}
                  hideCloseButton
                  lowContrast
                  style={{ minWidth: '100%', width: '100%' }}
                />
              ) : null}
              {allowLiveChanges ? (
                <p>
                  Are you sure you want to set this part as
                  {' '}
                  <span style={{ fontWeight: 800 }}>QUOTED</span>
                  ?
                  Once you click &quot;Confirm,&quot; the quote will be live.
                </p>
              ) : (
                <p>Please ask Andy, Josh, or Dylan to set this quote live.</p>
              )}
            </Modal>,
            document.body,
          )}
        {typeof document === 'undefined'
          ? null
          : ReactDOM.createPortal(
            <Modal
              size="md"
              open={isSetNoQuoteOpen}
              onRequestClose={() => { setIsSetNoQuoteOpen(false); }}
              modalHeading="Mark No Quote?"
              primaryButtonText="Confirm and send email"
              secondaryButtons={allowLiveChanges ? [
                { buttonText: 'Cancel', onClick: () => { setIsSetNoQuoteOpen(false); } },
                {
                  buttonText: 'Confirm without email',
                  onClick: async () => {
                    await handleChangeState('no_quote');
                    setIsSetNoQuoteOpen(false);
                  },
                },
              ] : [
                { buttonText: 'Cancel', onClick: () => { setIsSetNoQuoteOpen(false); } },
              ]}
              onRequestSubmit={async () => {
                setIsSubmitting(true);
                try {
                  setEmailError(null);
                  await sendEmail('no_quote');
                  await handleChangeState('no_quote');
                  setIsSetNoQuoteOpen(false);
                } catch (err) {
                  setEmailError(err.message);
                }
                setIsSubmitting(false);
              }}
              primaryButtonDisabled={!allowLiveChanges || isSubmitting}
            >
              { emailError ? (
                <InlineNotification
                  kind="error"
                  title="Error sending email."
                  subtitle={emailError}
                  hideCloseButton
                  lowContrast
                  style={{ minWidth: '100%', width: '100%' }}
                />
              ) : null}
              {allowLiveChanges ? (
                <p>
                  Are you sure you want to set this part as
                  {' '}
                  <span style={{ fontWeight: 800 }}>NO QUOTE</span>
                  ? Once you click &quot;Confirm,&quot; the quote will be live.
                </p>
              ) : (
                <p>Please ask Andy or Josh to set this quote live.</p>
              )}
            </Modal>,
            document.body,
          )}
        <ButtonDropdown
          items={[
            {
              text: 'Set quote live',
              id: 'quoted',
              disabled: !feedbackChecklistReviewed,
              disabledReason: 'Please review the part and provide feedback first.',
            },
            {
              text: 'Mark "no quote"',
              id: 'no_quote',
              disabled: !feedbackChecklistReviewed,
              disabledReason: 'Please review the part and provide feedback first.',
            },
          ]}
          onItemClick={({ detail }) => {
            switch (detail.id) {
              case 'quoted':
                setIsSetQuotedOpen(true);
                break;
              case 'no_quote':
                setIsSetNoQuoteOpen(true);
                break;
              default:
            }
          }}
        >
          Set quote live
        </ButtonDropdown>
      </>
    );
  }
  return (
    <>
      {typeof document === 'undefined'
        ? null
        : ReactDOM.createPortal(
          <Modal
            size="sm"
            open={isRevokeQuoteOpen}
            onRequestClose={() => { setIsRevokeQuoteOpen(false); }}
            modalHeading="Revoke quote?"
            primaryButtonText="Confirm"
            secondaryButtonText="Cancel"
            onRequestSubmit={() => { handleChangeState('pending'); setIsRevokeQuoteOpen(false); }}
          >
            <p>
              Are you sure you want to
              {' '}
              <span style={{ fontWeight: 800 }}>REVOKE</span>
              {' '}
              this quote?
            </p>
          </Modal>,
          document.body,
        )}
      <Button
        variant="primary"
        onClick={() => { setIsRevokeQuoteOpen(true); }}
      >
        Revoke quote
      </Button>
    </>
  );
}

QuoteStatus.propTypes = {
  partId: PropTypes.string.isRequired,
};

export default QuoteStatus;
